import $ from 'jquery';

export const filterForm = (): void => {
    $('#id_price_min, #id_price_max').on('keydown', (e) => {
        if (e.which !== 8 && e.which !== 0 && e.which !== 13 && e.which !== 44 && e.which !== 46 && (e.which < 48 || e.which > 57) && (e.which < 96 || e.which > 105)) {
            e.preventDefault();
        }
    });

    $('#id_sort, #id_in_stock').on('change', (e) => {
        $(e.target).closest('form').trigger('submit');
    });
};
